<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status','goods_logo','goods_put']"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加商品</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">上架</span>
                <span v-else-if="data.text=='0'">下架</span>
            </template>

            <template slot="goods_logo" slot-scope="data">
                <img style="width:50px; height:50px" :src="data.text" alt />
            </template>

            <template slot="goods_put" slot-scope="data">
                <span v-if="data.text=='1'">是</span>
                <span v-else-if="data.text=='0'">否</span>
            </template>

            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                        <a>删除</a>
                    </a-popconfirm>
                </span>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { competeList, changeCompete, competeDelete } from "@/api/goods";

let columns = [
    {
        title: "商品图片",
        dataIndex: "goods_logo",
        scopedSlots: {
            customRender: "goods_logo"
        }
    },
    {
        title: "商品条码",
        dataIndex: "goods_code",
    },
    {
        title: "商品名称",
        dataIndex: "goods_title",
    },
    // {
    //     title: "规格",
    //     dataIndex: "goods_spec",
    // },
    {
        title: "单位",
        dataIndex: "goods_spec",
        scopedSlots: {
            customRender: "goods_num"
        }
    },
    {
        title: "销售价",
        dataIndex: "goods_price",
    },
    {
        title: "排序",
        dataIndex: "sort",
    },

    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "IndexCompete",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: competeList,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "title",
                        title: "商品",
                        placeholder: '商品名称/条形码',
                        options: {

                        }
                    },

                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                    
                ],
                ...this.$config.form_data_seo
            },
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_title',
                        title: '商品名称',
                        options: {

                        },
                    },
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: [
                            {
                                key: 1,
                                value: "上架"
                            },
                            {
                                key: 0,
                                value: "下架"
                            }
                        ],
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_code',
                        title: '商品条码',
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_spec',
                        title: '规格',
                        options: {},
                    },
                    // {
                    //     type: 'text',
                    //     name: 'remarks',
                    //     title: '单位',
                    //     options: {},
                    // },
                    {
                        type: 'text',
                        name: 'sort',
                        title: '排序',
                        options: {},
                    },
                    // {
                    //     type: 'text',
                    //     name: 'discount_price',
                    //     title: '市场价',
                    //     options: {},
                    // },
                    {
                        type: 'text',
                        name: 'goods_price',
                        title: '销售价',
                        options: {},
                    },
                    // {
                    //     type: 'text',
                    //     name: 'remarks',
                    //     title: '起订量',
                    //     options: {},
                    // },

                    {
                        type: 'upload',
                        name: 'goods_logo',
                        title: '商品图片',
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remarks',
                        title: '备注',
                        options: {},
                    },
                ]
            }
        };
    },
    async created() {
		

    },
    methods: {
        add() {
            this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                item.options.initialValue = "";
                if (item.type == 'upload') {
                    item.options.initialValue = [];
                }
            })
        },
        edit(record) {
            this.form_data.title = "编辑"
            let data = record
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    let val = {};
                    val[item.name] = {
                        fileList: [{
                            url: data[item.name]
                        }]
                    }
                    item.options.initialValue = [{
                        uid: data[item.name],
                        name: data[item.name],
                        status: 'done',
                        url: data[item.name]
                    }];

                } else {
                    item.options.initialValue = data[item.name];
                }
            })
            this.visible = true;
        },
        del(record) {
            competeDelete({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            changeCompete({
                data: {
                    ...data
                },
            }).then(res => {
                this.$message.success(res.info);
                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>